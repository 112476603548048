













































































import { Vue, Component, Watch } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import Option from '@/types/option'
import User from '@/types/user'
import Column from '@/types/column'
import TableSettings from '@/types/tableSettings'
import ActiveFilters from '@/components/listview/ActiveFilters.vue'
import Pagination from '@/types/pagination'
import axios from 'axios'
import PageHeader from '@/components/base/PageHeader.vue'
import OptionBulkUpdater from '@/views/option/components/OptionBulkUpdater.vue'
import { notification } from 'ant-design-vue'

@Component({
  components: { OptionBulkUpdater, PageHeader, ActiveFilters }
})

export default class Options extends Vue {
  settings = {
    routeNames: { create: 'optionCreate', show: 'optionShow', edit: 'optionEdit' },
    routeParamKey: 'optionId',
    permissionNames: { show: 'options.view', edit: 'options.edit', delete: 'options.delete' },
    functionNames: { getList: 'getOptionList' },
    getterNames: { list: 'optionList', loading: 'loadingOption' },
    localStorageName: 'optionTableSettings'
  }

  columns:Column[] = [
    {
      title: 'Optie nr.',
      key: 'external_ref',
      dataIndex: 'external_ref',
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Naam',
      key: 'name',
      dataIndex: 'name',
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Prijs (incl. BTW)',
      key: 'price',
      scopedSlots: { customRender: 'price' },
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Prijs (excl. BTW)',
      key: 'price_ex_vat',
      scopedSlots: { customRender: 'price_ex_vat' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'BTW',
      key: 'vat_rate',
      dataIndex: 'vat_rate',
      scopedSlots: { customRender: 'vat_rate' },
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Budget (ex. BTW)',
      key: 'budget',
      scopedSlots: { customRender: 'budget' },
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Opslagen (ex. BTW)',
      key: 'additional_budget',
      scopedSlots: { customRender: 'additional_budget' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Marge (ex. BTW)',
      key: 'margin',
      scopedSlots: { customRender: 'margin' },
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Rendement',
      key: 'profitability',
      scopedSlots: { customRender: 'profitability' },
      filters: [
        { text: '< 0%', value: '<0' },
        { text: '0%', value: '0' },
        { text: '0-10%', value: '0-10' },
        { text: '> 10%', value: '>10' }
      ],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Aantal verkocht',
      key: 'times_sold',
      scopedSlots: { customRender: 'times_sold' },
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Fase',
      key: 'phase',
      dataIndex: 'phase_text',
      filters: [
        {
          text: 'Modulair',
          value: 0
        },
        {
          text: 'Ruwbouw',
          value: 1
        },
        {
          text: 'Afbouw',
          value: 2
        }
      ],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Groep',
      key: 'group',
      dataIndex: 'group_text',
      filters: [
        {
          text: 'Binnendeuren',
          value: 11
        },
        {
          text: 'Buiten',
          value: 16
        },
        {
          text: 'Douchewanden',
          value: 29
        },
        {
          text: 'Elektra',
          value: 10
        },
        {
          text: 'Extra',
          value: 99
        },
        {
          text: 'Keuken',
          value: 15
        },
        {
          text: 'Modulair',
          value: 17
        },
        {
          text: 'Overige',
          value: 14
        },
        {
          text: 'Ruwbouw',
          value: 28
        },
        {
          text: 'Sanitair',
          value: 13
        },
        {
          text: 'Stucwerk',
          value: 23
        },
        {
          text: 'Tegelwerk',
          value: 22
        },
        {
          text: 'Verwarming',
          value: 12
        }
      ],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Actief',
      key: 'active',
      scopedSlots: { customRender: 'active' },
      filters: [
        {
          text: 'Ja',
          value: 1
        },
        {
          text: 'Nee',
          value: 0
        }
      ],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      width: 120
    }
  ]

  tableSettings:TableSettings = new SecureLS({ isCompression: false }).get(this.settings.localStorageName) || {
    pagination: { current: 1, pageSize: 25 },
    showSizeChanger: true,
    pageSizeOptions: ['25', '50', '100', '250', '500'],
    activeFilters: null,
    sort: null,
    search: null
  }

  selectedRowKeys:number[] = []

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
    this.onTableSettingsChange()
  }

  destroyOption (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/options/' + id).then(() => {
      notification.success({ message: 'Optie verwijderd.', description: 'De optie is succesvol verwijderd.', duration: 3 })
      this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
    }).catch(() => {
      notification.error({ message: 'Optie NIET verwijderd.', description: 'De optie is NIET verwijderd. Probeer het later nog eens.', duration: 0 })
    })
  }

  handleTableChange (pagination:{ current:number, pageSize:number }, filters:{[key: string]: number[]|string[]}, sort:{ columnKey:string, order:string }) : void {
    // Remove key from filters if filter is an empty object.
    if (filters && Object.keys(filters).length) {
      Object.keys(filters).forEach((key) => {
        if (!filters[key] || !filters[key].length) {
          delete filters[key]

          this.columns.forEach((column:Column) => {
            if (column.key === key) {
              column.filteredValue = null
            }
          })
        }
      })
    }

    // Set pagination, filters and sorting on tableSettings.
    this.tableSettings.pagination = pagination
    this.tableSettings.activeFilters = filters
    this.tableSettings.sort = sort.order ? sort : null

    // Get list based on filters and search term.
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  handleSearchChange () : void {
    // Set page to 1 because of new search but retain filters and sorting.
    this.tableSettings.pagination.current = 1

    // Get list based on filters and search term.
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  // Required for ActiveFilters component
  updateFilters (filters: {[key: string]: number[]|string[]|boolean[]}) : void {
    const { columns } = this

    columns.forEach((column:Column) => {
      column.filteredValue = column.key in filters ? filters[column.key] : []
    })

    this.tableSettings.activeFilters = Object.assign({}, filters)
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          if (!this.selectedRowKeys.length) {
            const keyboard = window.event as KeyboardEvent
            if (this.authUser.permissions.includes(this.settings.permissionNames.edit)) {
              const route = this.$router.resolve({
                name: this.settings.routeNames.edit,
                params: { [this.settings.routeParamKey]: rowData.id.toString() }
              })
              window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
            } else if (this.authUser.permissions.includes(this.settings.permissionNames.show)) {
              const route = this.$router.resolve({
                name: this.settings.routeNames.show,
                params: { [this.settings.routeParamKey]: rowData.id.toString() }
              })
              window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
            }
          } else {
            this.selectedRowKeys.includes(rowData.id) ? this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(rowData.id), 1) : this.selectedRowKeys.push(rowData.id)
          }
        }
      }
    }
  }

  getRowClassName (record:{ total_budget:number, price_ex_vat:number, margin_additional_work:number }): string {
    if (record.total_budget > record.price_ex_vat || record.margin_additional_work < 0) {
      return 'budgetWarning'
    }

    return ''
  }

  getProfitabilityClass (profitability: number) : string {
    if (profitability === 0) return ''
    if (profitability < 0) return 'lowProfitability'
    if (profitability < 10) return 'mediumProfitability'
    return ''
  }

  onSelectChange (selectedRowKeys:number[]) : void {
    this.selectedRowKeys = selectedRowKeys
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get selectedOptions (): Option[] {
    return this.itemList.data.filter((option: Option) => this.selectedRowKeys.includes(option.id))
  }

  get itemList () : { data: Option[], meta: { per_page:string, total:number } } {
    return this.$store.getters[this.settings.getterNames.list]
  }

  get pagination () : Pagination {
    return {
      current: this.tableSettings.pagination.current,
      defaultPageSize: this.tableSettings.pagination.pageSize,
      pageSize: parseInt(this.itemList.meta.per_page),
      total: this.itemList.meta.total,
      showTotal: (total, range) => `Optie ${range[0]} t/m ${range[1]} van de ${total}`,
      showSizeChanger: this.tableSettings.showSizeChanger,
      pageSizeOptions: this.tableSettings.pageSizeOptions
    }
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].list
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }

  @Watch('tableSettings', { deep: true })
  onTableSettingsChange () : void {
    // On tableSettings change, store them in local storage.
    new SecureLS({ isCompression: false }).set(this.settings.localStorageName, this.tableSettings)

    axios.post(process.env.VUE_APP_API_URL + '/users/admin/settings', {
      settingName: this.settings.localStorageName,
      settingValue: this.tableSettings
    })

    // Set the current active filters on the columns.
    this.columns.forEach((column:Column) => {
      // Set active filters.
      if (this.tableSettings.activeFilters && column.key in this.tableSettings.activeFilters) {
        column.filteredValue = this.tableSettings.activeFilters[column.key]
      }

      // Set active sort order.
      if (this.tableSettings.sort && column.key === this.tableSettings.sort.columnKey) {
        column.defaultSortOrder = this.tableSettings.sort.order
        column.sortOrder = this.tableSettings.sort.order
      } else {
        column.sortOrder = false
      }
    })
  }
}
