


















import { Vue, Component, Prop } from 'vue-property-decorator'
import Issue from '@/types/issue'
import TextEditor from '@/components/base/TextEditor.vue'
import moment from 'moment/moment'

@Component({
  components: { TextEditor }
})

export default class PrioritySelector extends Vue {
  @Prop({ required: true, type: Boolean, default: false }) editable!: boolean

  moment = moment

  get currentItem (): Issue {
    return this.$store.getters['issueStore/current']
  }

  get loading (): boolean {
    return this.$store.getters['issueStore/loading'].item
  }

  disabledDate (currentDate: moment.Moment): boolean {
    return currentDate < moment().endOf('day')
  }
}
