import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import { notification } from 'ant-design-vue'
import axios from 'axios'
import moment from 'moment/moment'
import 'moment/locale/nl'
import router from '@/router'
import IssueModel from '@/models/IssueModel'
import Issue from '@/types/issue'
import ProjectModel from '@/models/ProjectModel'
import SecureLS from 'secure-ls'
import { ServiceStates } from '@/types/serviceItem'
moment.locale('nl')

Vue.use(Vuex)
const storeName = 'issues'
export const issueStore: Module<any, any> = {
  namespaced: true,
  state: {
    issueList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentIssue: new Issue(),
    issueFilters: [],
    issueActiveColumns: [],
    InactiveIssueColumns: new SecureLS({ isCompression: false }).get(storeName + '-columns') || [],
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {},
    companies: [],
    projects: [],
    buildings: [],
    employees: [],
    issueManagers: [],
    solvers: [],
    tags: []
  },
  mutations: {
    setCurrent (state, { currentIssue }) {
      state.currentIssue = currentIssue
      currentIssue.estimate = currentIssue.estimate ? moment(currentIssue.estimate) : null
      currentIssue.appointment_at = currentIssue.appointment_at ? moment(currentIssue.appointment_at) : null
    },
    clearCurrent (state) {
      state.currentIssue = new Issue()
    },
    clearIssueList (state) {
      state.issueList = {
        data: [],
        meta: {
          per_page: 25,
          total: 0
        }
      }
    },
    setList (state, { issueList }) {
      state.issueList = issueList
    },
    setFilters (state, { filters }) {
      state.issueFilters = filters.data
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    setUserId (state, id) {
      state.currentIssue.user_id = id
    },
    enabledOptionsChanged (state, { enabledOptions }) {
      state.currentIssue.enabled_options = enabledOptions
    },
    setProjects (state, projects) {
      state.projects = projects.data
    },
    setBuildings (state, buildings) {
      state.buildings = buildings.data
    },
    setCompanies (state, companies) {
      state.companies = companies.data
    },
    setEmployees (state, employees) {
      state.employees = employees
    },
    setManagers (state, managers) {
      state.issueManagers = managers
    },
    setManager (state, id) {
      state.currentIssue.manager_id = id
    },
    setInactiveColumns (state, columns) {
      state.InactiveIssueColumns = columns
    },
    setSolvers (state, solvers) {
      state.solvers = solvers
    },
    setEstimate (state, date) {
      state.currentIssue.estimate = date
    },
    setAdditionalInfo (state, info) {
      state.currentIssue.additional_info = info
    },
    setFiles (state, files) {
      state.currentIssue.files = files
    },
    removeFile (state, index) {
      state.currentIssue.files.splice(index, 1)
    },
    setSolverId (state, id) {
      state.currentIssue.solver_id = id
    },
    clearSolvers (state) {
      state.solvers = []
    },
    setProject (state, id) {
      state.currentIssue.project_id = id
    },
    setBuildingId (state, id) {
      state.currentIssue.building_id = id
    },
    setBuilding (state, building) {
      state.currentIssue.building = building
    },
    setStatus (state, statusId) {
      state.currentIssue.status = statusId
    },
    setRoomId (state, id) {
      state.currentIssue.room_id = id
    },
    setElementId (state, id) {
      state.currentIssue.element_id = id
    },
    setTags (state, tags) {
      state.currentIssue.tags = tags
    }
  },
  actions: {
    getList ({ commit }, { settings }) {
      commit('setLoading', { type: 'list', state: true })
      let issueModel = new IssueModel()

      // Handle Filters
      if (settings.activeFilters) {
        // Check if we want the archived items
        Object.keys(settings.archiveConditions).forEach(key => {
          if (settings.archiveConditions[key].length) {
            issueModel = issueModel.whereIn(key,
              Object.values(ServiceStates).filter(value => settings.archived
                ? settings.archiveConditions[key].includes(value)
                : !settings.archiveConditions[key].includes(value)))
          }
        })

        // Then check if they need to be filtered.
        Object.keys(settings.activeFilters).forEach(key => {
          if (settings.activeFilters[key].length) {
            issueModel = issueModel.whereIn(key, settings.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (settings.search) {
        issueModel = issueModel.where('search', settings.search)
      }

      // Add orderBy if sort is set.
      if (settings.sort) {
        issueModel = issueModel.orderBy(settings.sort.order === 'ascend' ? settings.sort.columnKey : '-' + settings.sort.columnKey)
      }

      if (settings.pagination) {
        issueModel = issueModel.limit(settings.pagination.pageSize).page(settings.pagination.current)
      }

      issueModel.get().then((issueList) => {
        commit('setList', { issueList: issueList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van medldingen!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    clearList ({ commit }) {
      commit('clearList')
    },
    setCurrent ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        IssueModel.$find(id).then((currentIssue) => {
          commit('setCurrent', { currentIssue: currentIssue })
          resolve(currentIssue)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze melding!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    setEstimate ({ commit }, date) {
      commit('setEstimate', date)
    },
    setAdditionalInfo ({ commit }, info) {
      commit('setAdditionalInfo', info)
    },
    setMedia ({ commit }, media) {
      commit('setFiles', media)
    },
    removeFile ({ commit }, index) {
      commit('removeFile', index)
    },
    setUserId ({ commit }, id) {
      commit('setUserId', id)
    },
    clearCurrent ({ commit }) {
      commit('clearCurrent')
    },
    getIssueFilters ({ commit }, data) {
      axios.post(process.env.VUE_APP_API_URL + '/issues/filters', data)
        .then((filters) => {
          commit('setFilters', { filters })
        })
        .catch(() => {
          notification.error({ message: 'Fout tijdens het ophalen van meldingen!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }).finally(() => {
          commit('setLoading', { type: 'list', state: false })
        })
    },
    setInactiveColumns ({ commit }, columns) {
      new SecureLS({ isCompression: false }).set(storeName + '-columns', columns)
      commit('setInactiveColumns', columns)
    },
    save ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      // suppressed since the results work perfectly.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { has_new_message, comments, ...payload } = data
      const issueModel = new IssueModel(payload)

      // Save issue.
      issueModel.save().then((currentIssue) => {
        commit('setErrors', {})
        commit('setCurrent', { currentIssue: currentIssue })
        if (data.id) {
          notification.success({ message: 'Melding opgeslagen.', description: 'De melding is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Melding toegevoegd.', description: 'De melding is succesvol toegevoegd.', duration: 3 })
          if (currentIssue.id) {
            router.push({ name: 'issueEdit', params: { issueId: currentIssue.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze melding!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze melding!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    },
    enabledOptionsChanged ({ commit }, enabledOptions) {
      commit('enabledOptionsChanged', { enabledOptions })
    },
    getProjects ({ commit }) {
      const projectModel = new ProjectModel()
      projectModel.get().then((projects) => {
        commit('setProjects', projects)
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van projecten!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    getBuildings ({ commit }, projectId) {
      commit('setErrors', {})
      axios.get(`${process.env.VUE_APP_API_URL}/buildings?filter[project.id]=${projectId}`)
        .then((res) => {
          commit('setBuildings', res.data)
        })
    },
    getCompanies ({ commit }) {
      axios.get(`${process.env.VUE_APP_API_URL}/companies/all`)
        .then((res) => {
          commit('setCompanies', res.data)
        })
    },
    getEmployees ({ commit, state }) {
      commit('setErrors', {})
      axios.get(`${process.env.VUE_APP_API_URL}/users?filter[roles.name]=employee`)
        .then((res) => {
          commit('setEmployees', res.data.data)
          if (state.currentIssue.manager_id === null) {
            state.currentIssue.manager_id = res.data.data[0].id
          }
        })
    },
    getManagers ({ commit, state }, projectId) {
      if (projectId === undefined) return
      commit('setErrors', {})
      axios.get(`${process.env.VUE_APP_API_URL}/members/${projectId}/mentions`)
        .then((res) => {
          commit('setManagers', res.data)
          if (state.currentIssue.manager_id === 0 && res.data.length > 0) {
            state.currentIssue.manager_id = res.data[0].id
          }
        })
    },
    getSolvers ({ commit }, companyId) {
      commit('setErrors', {})
      if (companyId === 0 || companyId === null) return
      commit('clearSolvers')
      axios.get(`${process.env.VUE_APP_API_URL}/companies/${companyId}/solvers`)
        .then((res) => {
          commit('setSolvers', res.data.data)
        })
    },
    setSolverId ({ commit }, id) {
      commit('setSolverId', id)
    },
    clearSolvers ({ commit }) {
      commit('clearSolvers')
    },
    setProject ({ commit }, id) {
      commit('setProject', id)
    },
    setBuildingId ({ commit }, id) {
      commit('setBuildingId', id)
    },
    setRoomId ({ commit }, id) {
      commit('setRoomId', id)
    },
    setElementId ({ commit }, id) {
      commit('setElementId', id)
    },
    setBuilding ({ commit }, building) {
      commit('setBuilding', building)
    },
    setStatus ({ commit }, statusId) {
      commit('setStatus', statusId)
    },
    setManager ({ commit }, id) {
      commit('setManager', id)
    },
    setTags ({ commit }, tags) {
      commit('setTags', tags)
    }
  },
  getters: {
    list: state => state.issueList,
    current: state => state.currentIssue,
    filters: state => state.issueFilters,
    loading: state => state.loading,
    errors: state => state.errors,
    activeColumns: state => state.issueActiveColumns,
    inactiveColumns: state => state.InactiveIssueColumns,
    companies: state => state.companies,
    projects: state => state.projects,
    buildings: state => state.buildings,
    managers: state => state.issueManagers,
    employees: state => state.employees,
    solvers: state => state.solvers
  }
}
