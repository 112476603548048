


















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import TextEditor from '@/components/base/TextEditor.vue'
import RevitElement from '@/types/revitElement'

@Component({
  components: { TextEditor }
})
export default class RevitElementDetailsTab extends Vue {
  @Prop({ type: Boolean, default: false }) editable!: boolean

  get currentItem () : RevitElement {
    return this.$store.getters['revitElementStore/currentItem']
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters['revitElementStore/errors']
  }

  getElementName (element: RevitElement) : string {
    return element.name !== 'Onbekend' ? element.name : element.revit_name
  }

  getRooms (element: RevitElement) : string {
    return element.rooms.join(', ')
  }
}
