import { render, staticRenderFns } from "./RevitElementDetailsTab.vue?vue&type=template&id=3cb52ecc&scoped=true&"
import script from "./RevitElementDetailsTab.vue?vue&type=script&lang=ts&"
export * from "./RevitElementDetailsTab.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cb52ecc",
  null
  
)

export default component.exports