



























import { Vue, Component, Prop } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import axios from 'axios'
import Building from '@/types/building'
import RevitElement from '@/types/revitElement'

@Component({
  components: { }
})

export default class RevitElementsTab extends Vue {
  @Prop({ type: Boolean, default: false }) editable!: boolean

  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  baseElements: RevitElement[] = []
  loading = false

  columns = [
    { title: 'Naam', dataIndex: 'name', key: 'name' },
    { title: 'Groep', dataIndex: 'group', key: 'group' },
    { title: 'Categorie', dataIndex: 'category', key: 'category' },
    { title: 'Assemblage code', dataIndex: 'assembly_code', key: 'assembly_code' },
    { title: 'Overige', dataIndex: 'other', key: 'other', scopedSlots: { customRender: 'other' } }
  ]

  mounted () : void {
    this.getRevitElements()
  }

  getRevitElements () : void {
    if (!this.currentItem.id) {
      return
    }
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/elements?type=base')
      .then((res) => {
        this.baseElements = res.data
      }).finally(() => {
        this.loading = false
      })
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          const route = this.$router.resolve({ name: 'revitElementEdit', params: { id: rowData.id.toString() } })
          window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
        }
      }
    }
  }

  get elements () : RevitElement[] {
    return this.baseElements
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get isDeveloper (): boolean {
    return this.authUser.roleNames.includes('developer')
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
