import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import { notification } from 'ant-design-vue'
import router from '@/router'
import Subelement from '@/types/subelement'
import RevitElement from '@/types/revitElement'
import RevitElementModel from '@/models/RevitElementModel'

Vue.use(Vuex)
export const revitElementStore: Module<any, any> = {
  namespaced: true,
  state: {
    itemList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentItem: new RevitElement(),
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentItem (state, { currentItem }) {
      state.currentItem = currentItem
    },
    clearCurrentItem (state) {
      state.currentItem = new RevitElement()
    },
    setItemList (state, { itemList }) {
      state.itemList = itemList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    addSubElement (state, { subelement }) {
      if (!state.currentItem.subelement_ids.includes(subelement.id)) {
        state.currentItem.subelement_ids.push(subelement.id)
        state.currentItem.subelements.push(subelement)
      }
    },
    deleteSubelement (state, { id }) {
      state.currentItem.subelement_ids.splice(state.currentItem.subelement_ids.indexOf(id), 1)

      let subelementIndex = null
      state.currentItem.subelements.forEach((subelement: Subelement, index: number) => {
        if (subelement.id === id) {
          subelementIndex = index
        }
      })

      if (subelementIndex !== null) {
        state.currentItem.subelements.splice(subelementIndex, 1)
      }
    }
  },
  actions: {
    getItemList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let model = new RevitElementModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            model = model.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        model = model.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        model = model.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        model = model.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      model.get().then((itemList) => {
        commit('setItemList', { itemList })
      }).catch(() => {
        notification.error({
          message: 'Fout tijdens het ophalen van de itemen!',
          description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
          duration: 3
        })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentItem ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        RevitElementModel.$find(id).then((currentItem) => {
          commit('setCurrentItem', { currentItem })
          resolve(currentItem)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van dit item!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentItem ({ commit }) {
      commit('clearCurrentItem')
    },
    saveElement ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const itemModel = new RevitElementModel(data)

      // Save item.
      itemModel.save().then((currentItem) => {
        commit('setErrors', {})
        commit('setCurrentItem', { currentItem })
        if (data.id) {
          notification.success({
            message: 'Item opgeslagen.',
            description: 'Het item is succesvol opgeslagen.',
            duration: 3
          })
        } else {
          notification.success({
            message: 'Item toegevoegd.',
            description: 'Het item is succesvol toegevoegd.',
            duration: 3
          })
          if (currentItem.id) {
            router.push({ name: 'itemEdit', params: { itemId: currentItem.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({
            message: 'Fout tijdens het opslaan van dit item!',
            description: 'Niet alle velden zijn correct ingevuld.',
            duration: 3
          })
        } else {
          notification.error({
            message: 'Fout tijdens het opslaan van dit item!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    },
    addSubElement ({ commit }, subelement) {
      commit('addSubElement', { subelement })
    },
    deleteSubElement ({ commit }, id) {
      commit('deleteSubelement', { id })
    }
  },
  getters: {
    list: state => state.itemList,
    currentItem: state => state.currentItem,
    loading: state => state.loading,
    errors: state => state.errors
  }
}
