












































































import { Vue, Component, Watch } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Customer from '@/types/customer'
import ServiceComments from '@/views/service/comments/ServiceComments.vue'
import ServiceCommentBlock from '@/views/service/comments/ServiceCommentBlock.vue'
import ServiceComment from '@/types/serviceComment'
import RelatedServiceItems from '@/views/service/RelatedServiceItems.vue'
import Issue from '@/types/issue'
import moment from 'moment/moment'
import ServiceObjectInfo from '@/views/service/ServiceObjectInfo.vue'
import Filelist from '@/components/media/Filelist.vue'
import Company from '@/types/company'
import Uploader from '@/components/media/Uploader.vue'
import ServiceCreateCommentMediaItem from '@/views/service/comments/ServiceCreateCommentMediaItem.vue'
import UploadQueue from '@/components/media/UploadQueue.vue'
import SimpleUploader from '@/components/media/SimpleUploader.vue'
import SimpleFileList from '@/components/media/SimpleFileList.vue'
import ServiceBuildingSelector from '@/views/service/ServiceBuildingSelector.vue'
import ServiceDetails from '@/views/service/ServiceDetails.vue'
import ServiceContactInfo from '@/views/service/ServiceContactInfo.vue'
import BuildingModel from '@/models/BuildingModel'
import ServiceElementSelector from '@/views/service/ServiceElementSelector.vue'
import PreviewContainer from '@/components/media/PreviewContainer.vue'
import axios from 'axios'
import SolverSelector from '@/views/service/components/SolverSelector.vue'
import PrioritySelector from '@/views/service/components/PrioritySelector.vue'

@Component({
  components: {
    PrioritySelector,
    SolverSelector,
    PreviewContainer,
    ServiceElementSelector,
    ServiceContactInfo,
    ServiceDetails,
    ServiceBuildingSelector,
    SimpleFileList,
    SimpleUploader,
    UploadQueue,
    ServiceCreateCommentMediaItem,
    Uploader,
    Filelist,
    IssueObjectInfo: ServiceObjectInfo,
    RelatedServiceItems,
    ServiceCommentBlock,
    ServiceComments,
    ServiceCreateComment: ServiceComments
  }
})

export default class IssuesCreate extends Vue {
  moment = moment
  config = {
    entity: 'issue',
    entities: 'issues',
    single: 'Melding',
    plural: 'Meldingen'
  }

  columnWidth = {
    labels: 10,
    values: 14
  }

  settings = {
    routeNames: { list: 'issues', show: 'issueShow', edit: 'issueEdit' },
    routeParamKey: 'issueId',
    permissionNames: { show: 'issues.view', edit: 'issues.edit' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('issueEditActiveTab') || 'details'
  search = {
    company: ''
  }

  @Watch('currentItem.solver_company_id')
  onSolverCompanyChanged () : void {
    this.$store.dispatch('issueStore/getSolvers', this.currentItem.solver_company_id)
  }

  destroyed (): void {
    this.$store.dispatch('issueStore/clearCurrentIssue')
  }

  get currentItem () : Issue {
    return this.$store.getters['issueStore/current']
  }

  get companies (): Company[] {
    let companies = this.$store.getters.companies

    companies = companies.filter((company: Company) => company.name.toLowerCase().includes(this.search.company.toLowerCase()))
    return companies
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters['issueStore/loading'].item
  }

  get saving () : boolean {
    return this.$store.getters['issueStore/loading'].save
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters['issueStore/errors']
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }

  get customer () : Customer {
    return this.currentItem.building?.customer || new Customer()
  }

  get comments () : ServiceComment[] {
    return this.currentItem.comments || [new ServiceComment()]
  }

  onBuildingSelected (id: number): void {
    this.$store.dispatch('issueStore/setBuildingId', id)

    BuildingModel.where('id', id).first().then(building => {
      this.$store.dispatch('issueStore/setBuilding', building)
    })
  }

  onProjectSelected (id: number): void {
    this.$store.dispatch('issueStore/setProject', id)
  }

  onManagerSelected (id: number): void {
    this.$store.dispatch('issueStore/setManager', id)
  }

  onRoomSelected (id: number): void {
    this.$store.dispatch('issueStore/setRoomId', id)
  }

  onElementSelected (id: number): void {
    this.$store.dispatch('issueStore/setElementId', id)
  }

  save () : void {
    const formData = new FormData()
    this.previewFiles.forEach((file:File) => {
      formData.append('files[]', file)
    })
    formData.append('title', this.currentItem.title)
    formData.append('description', this.currentItem.description)
    formData.append('building_id', this.currentItem.building_id.toString())
    formData.append('project_id', this.currentItem.project_id.toString())
    formData.append('source', this.currentItem.source)
    formData.append('user_id', this.authUser.id.toString())
    formData.append('type', this.currentItem.type.toString())
    formData.append('priority', this.currentItem.priority.toString())

    if (this.currentItem.estimate) {
      formData.append('estimate', this.currentItem.estimate.toString())
    }
    if (this.currentItem.solver_company_id) {
      formData.append('solver_company_id', this.currentItem.solver_company_id.toString())
    }
    if (this.currentItem.solver_id) {
      formData.append('solver_id', this.currentItem.solver_id.toString())
    }
    if (this.currentItem.manager_id) {
      formData.append('manager_id', this.currentItem.manager_id.toString())
    }
    if (this.currentItem.room_id) {
      formData.append('room_id', this.currentItem.room_id.toString())
    }
    if (this.currentItem.element_id) {
      formData.append('element_id', this.currentItem.element_id.toString())
    }
    if (this.currentItem.contact_name) {
      formData.append('contact_name', this.currentItem.contact_name)
    }
    if (this.currentItem.contact_phone) {
      formData.append('contact_phone', this.currentItem.contact_phone)
    }
    if (this.currentItem.contact_email) {
      formData.append('contact_email', this.currentItem.contact_email)
    }

    const url = `${process.env.VUE_APP_API_URL}/issues`

    axios.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      this.$message.success('Melding opgeslagen!', 5)
      this.$router.push({ name: 'issueEdit', params: { issueId: response.data.id.toString() } })
    }).catch(error => {
      this.$message.error('Er is iets misgegaan tijdens het opslaan van de melding!', 5)
      console.log(error)
    })
  }

  previewFiles:File[] = []

  async handleMedia (file: File): Promise<void> {
    this.previewFiles = [...[file], ...this.previewFiles]
    await this.$store.dispatch('issueStore/setMedia', this.previewFiles)
  }

  deleteFile (target: { index: number, file: File}) : void {
    this.previewFiles.splice(target.index, 1)
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('issueEditActiveTab', tab)
    this.currentTab = tab
  }

  projectChanged () : void {
    this.$store.dispatch('issueStore/getBuildings', this.currentItem.project_id)
    this.$store.dispatch('issueStore/getManagers', this.currentItem.project_id)
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }
}
