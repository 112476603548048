











import { Vue, Component } from 'vue-property-decorator'
import RevitElement from '@/types/revitElement'
import Column from '@/types/column'
import SecureLS from 'secure-ls'

@Component({})
export default class RevitElementSubElementsTab extends Vue {
  ls = new SecureLS({ isCompression: false })
  authUser = this.ls.get('authUser')
  columns:Column[] = [
    {
      title: 'Referentie',
      key: 'reference',
      dataIndex: 'reference',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Naam',
      key: 'name',
      dataIndex: 'name',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    }
  ]

  get canEdit () : boolean {
    return this.authUser.permissions.includes('elements.edit')
  }

  get currentItem () : RevitElement {
    return this.$store.getters.currentElement
  }

  get loading () : boolean {
    return this.$store.getters['revitElementStore/loading'].item
  }

  get saving () : boolean {
    return this.$store.getters['revitElementStore/loading'].save
  }
}
