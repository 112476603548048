import Subelement from '@/types/subelement'
import Project from '@/types/project'

export default class RevitElement {
  [key: string]: any;
  public id!:number
  public project!:Project
  public name!:string
  public name_sales!:string
  public text_sales!:string
  public text_technical!:string
  public phase!:number
  public phase_label!:string
  public group!:number
  public group_label!:string
  public height!:string
  public selector_type!:string
  public subelement_ids!:number[]
  public subelements!:Subelement[]
  public properties!: { id: number, key: string, value: string }[]
}
