



















import { Vue, Component, Prop } from 'vue-property-decorator'
import Column from '@/types/column'
import User from '@/types/user'
import SecureLS from 'secure-ls'

@Component({
  components: {}
})

export default class CustomerUserConnectedCustomersCard extends Vue {
  @Prop() customers!:{ id:number, name:string, reference:string }[]

  columns:Column[] = [
    {
      title: 'Referentie',
      key: 'reference',
      dataIndex: 'reference'
    },
    {
      title: 'Contactpersoon',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'E-mail',
      key: 'email',
      scopedSlots: { customRender: 'email' }
    },
    {
      title: 'Telefoon',
      key: 'phone',
      scopedSlots: { customRender: 'phone' }
    },
    {
      title: 'Adres',
      key: 'address',
      scopedSlots: { customRender: 'address' }
    },
    {
      title: 'Actie\'s',
      key: 'actions',
      scopedSlots: { customRender: 'actions' }
    }
  ]

  navigateTo (routeName: string, paramKey?: string, id?: number) : void {
    const keyboard = window.event as KeyboardEvent
    const routeParams = paramKey && id ? { [paramKey]: id.toString() } : undefined
    const route = this.$router.resolve({ name: routeName, params: routeParams })

    window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
