























































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Project from '@/types/project'
import moment from 'moment'
import Company from '@/types/company'
import axios from 'axios'
import ConstructionType from '@/types/constructionType'
import Uploader from '@/components/media/Uploader.vue'
import Filelist from '@/components/media/Filelist.vue'
import AfasField from '@/components/afas/AfasField.vue'

@Component({
  components: { AfasField, Filelist, Uploader }
})

export default class ProjectShowDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject', errors: 'projectErrors' }
  }

  buildings:Building[] = []
  companies:Company[] = []
  constructionTypes:ConstructionType[] = []

  mounted () : void {
    if (this.currentItem.id) {
      this.getBuildings()
    }

    this.getCompanies()
    this.getConstructionTypes()
  }

  getBuildings () : void {
    axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[project_id]=' + this.currentItem.id).then((res) => {
      this.buildings = res.data.data
    })
  }

  getCompanies () : void {
    axios.get(process.env.VUE_APP_API_URL + '/companies').then((res) => {
      this.companies = res.data.data
    })
  }

  getConstructionTypes () : void {
    axios.get(process.env.VUE_APP_API_URL + '/constructionTypes').then((res) => {
      this.constructionTypes = res.data.data
    })
  }

  reloadProject () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  moment () : moment.Moment {
    return moment()
  }

  get currentConstructionTypes () : string {
    const currentConstructionTypes:string[] = []
    this.constructionTypes.forEach((constructionType:ConstructionType) => {
      if (this.currentItem.construction_type_ids.includes(constructionType.id)) {
        currentConstructionTypes.push(constructionType.name)
      }
    })

    return currentConstructionTypes.join(', ')
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get isAfasProject () : boolean {
    return this.currentItem.in_afas
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
