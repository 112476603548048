


































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Column from '@/types/column'
import axios from 'axios'
import Layout from '@/types/layout'
import { notification } from 'ant-design-vue'
import TextEditor from '@/components/base/TextEditor.vue'

@Component({
  components: { TextEditor }
})

export default class BuildingEditLayoutSettingsTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  columns:Column[] = [
    {
      title: 'Layout nr.',
      key: 'external_ref',
      scopedSlots: { customRender: 'external_ref' }
    },
    {
      title: 'Layout',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Standaard prijs',
      key: 'price',
      scopedSlots: { customRender: 'price' }
    },
    {
      title: 'Project prijs',
      key: 'project_layouts.price',
      scopedSlots: { customRender: 'project_price' }
    },
    {
      title: 'Bouwnummer prijs',
      key: 'building_layouts.price',
      scopedSlots: { customRender: 'building_price' }
    },
    {
      title: 'Actief',
      key: 'active',
      scopedSlots: { customRender: 'active' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  loading = false
  modalVisible = false
  buildingLayouts = []
  currentLayout:{ [key: string]: string|number|null } = {
    building_id: null,
    layout_id: null,
    external_ref: null,
    name: null,
    base_price: null,
    base_purchase_price: null,
    project_price: null,
    project_purchase_price: null,
    building_price: null,
    building_purchase_price: null,
    building_text_sales: null,
    building_default: 0
  }

  mounted () : void {
    if (this.currentItem.id) {
      this.getBuildingLayouts()
    }
  }

  getBuildingLayouts () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/layouts').then((res) => {
      this.buildingLayouts = res.data
    }).finally(() => {
      this.loading = false
    })
  }

  showModal (item:Layout) : void {
    this.modalVisible = true
    this.currentLayout = {
      building_id: this.currentItem.id,
      layout_id: item.id,
      external_ref: item.external_ref,
      name: item.name,
      base_price: item.price,
      base_purchase_price: item.purchase_price,
      project_price: item.project_layouts && item.project_layouts.price !== null ? item.project_layouts.price : item.price,
      project_purchase_price: item.project_layouts && item.project_layouts.purchase_price !== null ? item.project_layouts.purchase_price : item.purchase_price,
      building_price: item.building_layouts && item.building_layouts.price !== null ? item.building_layouts.price : (item.project_layouts && item.project_layouts.price !== null ? item.project_layouts.price : item.price),
      building_purchase_price: item.building_layouts && item.building_layouts.purchase_price !== null ? item.building_layouts.purchase_price : (item.project_layouts && item.project_layouts.purchase_price !== null ? item.project_layouts.purchase_price : item.purchase_price),
      building_text_sales: item.building_layouts && item.building_layouts.text_sales !== null ? item.building_layouts.text_sales : (item.project_layouts && item.project_layouts.text_sales !== null ? item.project_layouts.text_sales : item.text_sales),
      building_default: item.building_layouts && item.building_layouts.default !== null ? item.building_layouts.default : (item.project_layouts && item.project_layouts.default !== null ? item.project_layouts.default : item.default)
    }
  }

  hideModal () : void {
    this.modalVisible = false
    this.currentLayout = {
      building_id: null,
      layout_id: null,
      external_ref: null,
      name: null,
      base_price: null,
      base_purchase_price: null,
      project_price: null,
      project_purchase_price: null,
      building_price: null,
      building_purchase_price: null,
      building_text_sales: null,
      building_default: 0
    }
  }

  storeLayout () : void {
    this.loading = true
    axios.post(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/layouts', this.currentLayout).then((res) => {
      this.buildingLayouts = res.data
      this.hideModal()
      notification.success({ message: 'Layout instellingen opgeslagen.', description: 'De layout instellingen zijn succesvol opgeslagen.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Layout instellingen NIET opgeslagen.', description: 'De layout instellingen zijn NIET opgeslagen.', duration: 0 })
    }).finally(() => {
      this.loading = false
    })
  }

  deleteLayout (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/layouts/' + id).then((res) => {
      this.buildingLayouts = res.data
      this.hideModal()
      notification.success({ message: 'Layout instelling gereset.', description: 'De layout instelling is succesvol gereset.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Layout instelling is NIET gereset.', description: 'De layout instelling is NIET gereset.', duration: 0 })
    }).finally(() => {
      this.loading = false
    })
  }

  toggleLayout (layoutId:number, active:boolean) : void {
    axios.post(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/layouts/' + layoutId + '/toggle', {
      disabled: !active
    }).catch(() => {
      this.buildingLayouts.forEach((l:{ id:number, active:boolean }) => {
        if (l.id === layoutId) {
          l.active = !active
        }
      })
      notification.error({ message: 'Fout tijdens het wijzigen van de layout!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
    })
  }

  contentChanged (key:string, value:string) : void {
    this.currentLayout[key] = value
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get permissionBasedColumns () : Column[] {
    if (!this.authUser.permissions.includes('buildings.buildingLayouts.edit')) {
      return this.columns.filter(c => c.key !== 'action')
    }

    return this.columns
  }
}
