import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import CustomerUserModel from '@/models/CustomerUserModel'
import router from '@/router'

Vue.use(Vuex)

export const customerUserStore: Module<any, any> = {
  state: {
    customerUserList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentCustomerUser: {
      firstname: null,
      lastname: null,
      full_name: null,
      username: null,
      password: null,
      password_confirmation: null,
      company_id: null,
      customers: [],
      role_ids: [],
      permission_ids: []
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentCustomerUser (state, { currentCustomerUser }) {
      currentCustomerUser.password = null
      currentCustomerUser.password_confirmation = null
      state.currentCustomerUser = currentCustomerUser
    },
    clearCurrentCustomerUser (state) {
      state.currentCustomerUser = {
        firstname: null,
        lastname: null,
        full_name: null,
        username: null,
        password: null,
        password_confirmation: null,
        company_id: null,
        customers: [],
        role_ids: [],
        permission_ids: []
      }
    },
    setCustomerUserList (state, { customerUserList }) {
      state.customerUserList = customerUserList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    }
  },
  actions: {
    getCustomerUserList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let customerUserModel = new CustomerUserModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            customerUserModel = customerUserModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        customerUserModel = customerUserModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        customerUserModel = customerUserModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        customerUserModel = customerUserModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      customerUserModel.get().then((customerUserList) => {
        commit('setCustomerUserList', { customerUserList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van klanten!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentCustomerUser ({ commit }, id) {
      commit('setErrors', {})
      commit('setLoading', { type: 'item', state: true })
      CustomerUserModel.$find(id).then((currentCustomerUser) => {
        commit('setCurrentCustomerUser', { currentCustomerUser })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van deze klant!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'item', state: false })
      })
    },
    clearCurrentCustomerUser ({ commit }) {
      commit('clearCurrentCustomerUser')
    },
    saveCustomerUser ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const customerUserModel = new CustomerUserModel(data)

      // Save user.
      customerUserModel.save().then((currentCustomerUser) => {
        commit('setErrors', {})
        commit('setCurrentCustomerUser', { currentCustomerUser })
        if (data.id) {
          notification.success({ message: 'Klant opgeslagen.', description: 'De klant is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Klant toegevoegd.', description: 'De klant is succesvol toegevoegd.', duration: 3 })
          if (currentCustomerUser.id) {
            router.push({ name: 'customerUserEdit', params: { customerUserId: currentCustomerUser.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze klant!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze klant!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    }
  },
  getters: {
    customerUserList: state => state.customerUserList,
    currentCustomerUser: state => state.currentCustomerUser,
    loadingCustomerUser: state => state.loading,
    customerUserErrors: state => state.errors
  }
}
