import Project from '@/types/project'
import Buildingblock from '@/types/buildingblock'
import Customer from '@/types/customer'
import Room from '@/types/room'
import MediaItem from '@/types/mediaItem'
import moment from 'moment'
import Tenant from '@/types/tenant'
import NewsItem from '@/types/newsItem'
import Column from '@/types/column'

class FivePercent {
  public active!:boolean
  public manager_id!:number|null
  public manager!:{ id:number, full_name:string }|null
  public hold!:boolean
  public hold_at!:string
  public released_at!:string
  public hold_amount!:string
  public hold_by_user!:{ id:number, full_name:string }
  public info_letter_sent_at!:string
  public all_resolved_at!:string
  public all_resolved_sent_at!:string|null
  public request_for_release_sent_at!:string|null
  public request_for_release_reminder_sent_at!:string|null
  public can_send_reminder!:boolean
  public is_deleted!:boolean
  public soft_deleted!:{ released_at:string|null }
}

export default class Building {
  [key: string]: any;
  public id!:number
  public number!:number
  public external_number!:string
  public reference!:string
  public state!:number
  public price!:number
  public contract_sum!:number
  public ready_for_invoicing!:boolean
  public delivery_can_be_scheduled!:boolean
  public description!:number
  public internal_note!:number
  public product_type!:number
  public object_type!:number
  public type!:number
  public address!:string
  public zipcode!:string
  public city!:string
  public building_width!:number
  public building_volume!:number
  public lot_size!:number
  public garden_depth!:number
  public garden_position!:string
  public ean_power!:string
  public certificate!:string
  public demo_available!:boolean
  public external!:boolean
  public unity_enabled!:boolean
  public end_modular!:string
  public status_modular_label!:string
  public end_carcass!:string
  public status_carcass_label!:string
  public end_completion!:string
  public status_completion_label!:string
  public for_sale_date!:string
  public sale_date!:string
  public start_construction_date!:string
  public delivery_date!:string
  public start_warranty!:string
  public in_afas!:boolean
  public last_sync_at!:string|null
  public build_order!:number
  public five_percent_rule!:FivePercent
  public project_id!:number
  public project?:Project
  public buildingblock_id!:number
  public buildingblock?:Buildingblock
  public enabled_options!:number[]
  public customer_id!:number
  public customer!:Customer
  public tenant_id!:number
  public tenant!:Tenant
  public rooms!:Room[]
  public levels!:number[]
  public news!:NewsItem[]

  public received_orders!:{
    modular:{ received:boolean, order_id:number|null },
    carcass:{ received:boolean, order_id:number|null },
    completion:{ received:boolean, order_id:number|null }
    extra:{ received:boolean, order_ids:number[]|null }
  }

  public inspection!:{
    id:number,
    scheduled_at:moment.Moment|null,
    scheduled_user_id:number,
    completed_at:moment.Moment|null
  }

  public delivery!:{
    id:number
    inspection_scheduled_at:moment.Moment|null
    inspection_scheduled_user_id:number
    inspection_completed_at:moment.Moment|null
    inspection_status:number
    delivery_scheduled_at:moment.Moment|null
    delivery_scheduled_user_id:number
    delivery_completed_at:moment.Moment|null
    delivery_status:number
    delivery_letter_sent_at:string|null
  }

  public media!:{
    buildingImage:MediaItem[],
    verkoopdocumentatie:MediaItem[],
    gebruikershandleiding:MediaItem[],
    revisiedocumenten:MediaItem[],
    kwaliteitdossier:MediaItem[],
    overig:MediaItem[],
    floorplans:{ [key:string]: MediaItem[] }
    facades:{ [key:string]: MediaItem[] }
  }

  constructor () {
    this.number = 0
  }
}

export const productTypes = [
  {
    id: 1,
    name: 'Grondgebonden'
  },
  {
    id: 2,
    name: 'Appartement'
  },
  {
    id: 3,
    name: 'Garage'
  },
  {
    id: 4,
    name: 'VvE'
  },
  {
    id: 5,
    name: 'Overig'
  }
]

export const objectTypes = [
  {
    id: 1,
    name: 'Koopwoning'
  },
  {
    id: 2,
    name: 'Huurwoning Tuvast'
  },
  {
    id: 3,
    name: 'Huurwoning extern'
  }
]

export const saleStates = [
  {
    id: 0,
    name: 'Binnenkort te koop'
  },
  {
    id: 7,
    name: 'Binnenkort te huur'
  },
  {
    id: 1,
    name: 'Te koop'
  },
  {
    id: 5,
    name: 'Te huur'
  },
  {
    id: 3,
    name: 'Mondeling verkocht'
  },
  {
    id: 4,
    name: 'Onder optie'
  },
  {
    id: 2,
    name: 'Verkocht'
  },
  {
    id: 6,
    name: 'Verhuurd'
  }
]

export const buildingPhaseStates = [
  {
    id: 'test',
    name: 'Test'
  },
  {
    id: 'open',
    name: 'Open'
  },
  {
    id: 'closed',
    name: 'Gesloten'
  },
  {
    id: 'processed',
    name: 'Verwerkt'
  },
  {
    id: 'expired',
    name: 'Verlopen'
  },
  {
    id: 'demo',
    name: 'Demo'
  }
]

export const columns:Column[] = [
  {
    title: 'Bouwnummer',
    key: 'number',
    dataIndex: 'number',
    filters: [],
    sorter: true,
    sortDirections: ['ascend', 'descend']
  },
  {
    title: 'Referentie',
    key: 'reference',
    dataIndex: 'reference',
    filters: [],
    sorter: true,
    sortDirections: ['ascend', 'descend']
  },
  {
    title: 'Project',
    key: 'project.id',
    dataIndex: 'project',
    scopedSlots: { customRender: 'project' },
    filters: [],
    sorter: false,
    sortDirections: ['ascend', 'descend']
  },
  {
    title: 'Blok',
    key: 'buildingblock.name',
    dataIndex: 'buildingblock.name',
    filters: [],
    sorter: false,
    sortDirections: ['ascend', 'descend']
  },
  {
    title: 'Adres',
    key: 'address',
    scopedSlots: { customRender: 'address' }
  },
  {
    title: 'Type',
    key: 'product_type',
    dataIndex: 'product_type',
    filters: productTypes.map((productType) => { return { text: productType.name, value: productType.id } }),
    scopedSlots: { customRender: 'productType' },
    sorter: false
  },
  {
    title: 'Status',
    key: 'state',
    dataIndex: 'state_label',
    filters: [
      {
        text: 'Binnenkort te koop',
        value: 0
      },
      {
        text: 'Te koop',
        value: 1
      },
      {
        text: 'Verkocht',
        value: 2
      },
      {
        text: 'Mondeling verkocht',
        value: 3
      },
      {
        text: 'Onder optie',
        value: 4
      },
      {
        text: 'Te huur',
        value: 5
      },
      {
        text: 'Verhuurd',
        value: 6
      },
      {
        text: 'Binnenkort te huur',
        value: 7
      }
    ],
    sorter: false,
    sortDirections: ['ascend', 'descend']
  },
  {
    title: 'Koper',
    key: 'customer',
    scopedSlots: { customRender: 'customer' }
  },
  {
    title: 'Koper in AFAS',
    key: 'customer.in_afas',
    scopedSlots: { customRender: 'customer.in_afas' },
    filters: [
      {
        text: 'Ja',
        value: 1
      },
      {
        text: 'Nee',
        value: 0
      }
    ]
  },
  {
    title: 'Gereed voor facturatie',
    key: 'ready_for_invoicing',
    scopedSlots: { customRender: 'ready_for_invoicing' },
    filters: [
      {
        text: 'Ja',
        value: 1
      },
      {
        text: 'Nee',
        value: 0
      }
    ],
    sorter: false,
    sortDirections: ['ascend', 'descend']
  },
  {
    title: 'Opleverdatum',
    key: 'delivery_date',
    scopedSlots: { customRender: 'delivery_date' },
    filters: [],
    sorter: true,
    sortDirections: ['ascend', 'descend']
  },
  {
    title: 'Actie\'s',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export const buildingMediaFolders: { name:string, position:string, allowedMimeTypes:string[], maxFileSize:number, allowFileRename:boolean, visibleFor:string[] }[] = [
  {
    name: 'Gebruikershandleiding',
    position: 'gebruikershandleiding',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true,
    visibleFor: ['Koper', 'Huurder']
  },
  {
    name: 'Revisiedocumenten',
    position: 'revisiedocumenten',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true,
    visibleFor: ['Koper']
  },
  {
    name: 'Kwaliteitdossier',
    position: 'kwaliteitdossier',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true,
    visibleFor: ['Koper']
  },
  {
    name: 'Contracten',
    position: 'contracten',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true,
    visibleFor: ['Koper']
  },
  {
    name: 'Overig',
    position: 'overig',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true,
    visibleFor: ['Koper', 'Huurder']
  }
]
export const buildingCustomerMediaFolders: { name:string, position:string, allowedMimeTypes:string[], maxFileSize:number, allowFileRename:boolean, visibleFor:string[] }[] = [
  {
    name: 'Verkoopdocumentatie',
    position: 'verkoopdocumentatie',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true,
    visibleFor: ['Koper']
  }
]

export const buildingTenantMediaFolders: { name:string, position:string, allowedMimeTypes:string[], maxFileSize:number, allowFileRename:boolean, visibleFor:string[] }[] = [
  {
    name: 'Verhuurdocumentatie',
    position: 'verhuurdocumentatie',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true,
    visibleFor: ['Huurder']
  }
]
