



















































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import BuildingEditDetailsTab from '@/views/building/tabs/edit/BuildingEditDetailsTab.vue'
import BuildingEditLotTab from '@/views/building/tabs/edit/BuildingEditLotTab.vue'
import BuildingEditDatesTab from '@/views/building/tabs/edit/BuildingEditDatesTab.vue'
import BuildingEditLayoutSettingsTab from '@/views/building/tabs/edit/BuildingEditLayoutSettingsTab.vue'
import BuildingEditEnabledOptionsTab from '@/views/building/tabs/edit/BuildingEditEnabledOptionsTab.vue'
import BuildingEditOrdersTab from '@/views/building/tabs/edit/BuildingEditOrdersTab.vue'
import BuildingEditOptionSettingsTab from '@/views/building/tabs/edit/BuildingEditOptionSettingsTab.vue'
import BuildingEditConfigurationsTab from '@/views/building/tabs/edit/BuildingEditConfigurationsTab.vue'
import BuildingEditReportsTab from '@/views/building/tabs/edit/BuildingEditReportsTab.vue'
import BuildingEditRoomsTab from '@/views/building/tabs/edit/BuildingEditRoomsTab.vue'
import axios from 'axios'
import Customer from '@/types/customer'
import ActivityLogTable from '@/components/ActivityLogTable.vue'
import BuildingEditIssuesTab from '@/views/building/tabs/edit/BuildingEditIssuesTab.vue'
import Tenant from '@/types/tenant'
import BuildingEditSurveysTab from '@/views/building/tabs/edit/BuildingEditSurveysTab.vue'
import BuildingUnityViewerTab from '@/views/building/tabs/BuildingUnityViewerTab.vue'
import PageHeader from '@/components/base/PageHeader.vue'
import BuildingEditNewsTab from '@/views/building/tabs/edit/BuildingEditNewsTab.vue'
import BuildingDocumentsTab from '@/views/building/tabs/BuildingDocumentsTab.vue'
import ConnectCustomer from '@/components/customers/ConnectCustomer.vue'
import ConnectTenant from '@/components/customers/ConnectTenant.vue'
import BuildingEditFivePercentTab from '@/views/building/tabs/edit/BuildingEditFivePercentTab.vue'
import BuildingDrawingsTab from '@/views/building/tabs/BuildingDrawingsTab.vue'
import RevitElementsTab from '@/views/building/tabs/RevitElementsTab.vue'
import SyncBuildingCustomerToAfasButton from '@/components/building/SyncBuildingCustomerToAfasButton.vue'
import BuildingFinancialTab from '@/views/building/tabs/BuildingFinancialTab.vue'

@Component({
  components: { BuildingFinancialTab, SyncBuildingCustomerToAfasButton, RevitElementsTab, BuildingDrawingsTab, BuildingEditFivePercentTab, ConnectTenant, ConnectCustomer, BuildingDocumentsTab, BuildingEditNewsTab, PageHeader, BuildingUnityViewerTab, BuildingEditSurveysTab, BuildingEditIssuesTab, ActivityLogTable, BuildingEditRoomsTab, BuildingEditReportsTab, BuildingEditConfigurationsTab, BuildingEditOptionSettingsTab, BuildingEditOrdersTab, BuildingEditEnabledOptionsTab, BuildingEditLayoutSettingsTab, BuildingEditLotTab, BuildingEditDatesTab, BuildingEditDetailsTab }
})

export default class BuildingEdit extends Vue {
  connectCustomerModalVisible = false
  selectedCustomerId = 0
  selectedCustomer?:Customer

  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', clearCurrent: 'clearCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('buildingEditActiveTab') || 'details'

  customers:Customer[] = []
  customerTimer = 0
  tenants:Tenant[] = []
  tenantTimer = 0

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey]).then(() => {
      if (this.currentItem.customer?.reference) {
        this.searchCustomer(this.currentItem.customer.reference)
      }
      if (this.currentItem.tenant?.reference) {
        this.searchTenant(this.currentItem.tenant.reference)
      }
    })
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  searchCustomer (val:string) : void {
    if (val.length >= 3) {
      clearTimeout(this.customerTimer)

      this.customerTimer = setTimeout(() => {
        axios.get(process.env.VUE_APP_API_URL + '/customers?filter[search]=' + val).then((res) => {
          this.customers = res.data.data
        })
      }, 500)
    }
  }

  searchTenant (val:string) : void {
    if (val.length >= 3) {
      if (val.length >= 3) {
        clearTimeout(this.tenantTimer)

        this.tenantTimer = setTimeout(() => {
          axios.get(process.env.VUE_APP_API_URL + '/tenants?filter[search]=' + val).then((res) => {
            this.tenants = res.data.data
          })
        }, 500)
      }
    }
  }

  showCustomerModal () : void {
    this.selectedCustomerId = 0
    this.connectCustomerModalVisible = true
  }

  closeCustomerModal () : void {
    this.connectCustomerModalVisible = false
  }

  updateCustomer () : void {
    this.$store.dispatch('customerChanged', this.selectedCustomer)
    this.closeCustomerModal()
  }

  onCustomerChange (id: number) : void {
    this.selectedCustomerId = id
    this.selectedCustomer = this.customers.find((customer:Customer) => {
      return customer.id === id
    })
  }

  onTenantChange (id:number) : void {
    const newTenant = this.tenants.find((tenant:Tenant) => {
      return tenant.id === id
    })

    this.$store.dispatch('tenantChanged', newTenant)
  }

  resetCustomer () : void {
    this.$store.dispatch('resetCustomer')
  }

  resetTenant () : void {
    this.$store.dispatch('resetTenant')
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string) : void {
    if (tab === 'unity-model') return // Don't store the viewer tab
    new SecureLS({ isCompression: false }).set('buildingEditActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  syncBuilding () : void {
    this.$store.dispatch('syncBuilding', this.currentItem.id)
  }

  get pageHeader () : string {
    const header = this.currentItem.project
      ? `Bouwnummer ${this.currentItem.number} (${this.currentItem.reference}) - ${this.currentItem.project.name} (${this.currentItem.project.reference})`
      : `Bouwnummer ${this.currentItem.number} (${this.currentItem.reference})`
    return this.loading ? 'Bouwnummer - Aan het laden..' : header
  }

  get canEditCustomer () : boolean {
    return this.currentItem.customer && this.authUser.permissions.includes('customers.edit')
  }

  get canEditTenant () : boolean {
    return this.currentItem.tenant !== null && this.authUser.permissions.includes('tenants.edit')
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get syncing () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].syncing
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
