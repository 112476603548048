









































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Survey from '@/types/survey'
import Project from '@/types/project'
import axios from 'axios'
import TextEditor from '@/components/base/TextEditor.vue'
import moment from 'moment'
import 'moment/locale/nl'
import SurveyForm from '@/types/surveyForm'
moment.locale('nl')

@Component({
  components: { TextEditor }
})

export default class SurveyEditDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'surveys', show: 'surveyShow', edit: 'surveyEdit' },
    routeParamKey: 'surveyId',
    functionNames: { setCurrent: 'setCurrentSurvey', save: 'saveSurvey' },
    getterNames: { current: 'currentSurvey', loading: 'loadingSurvey', errors: 'surveyErrors' }
  }

  projects:Project[] = []
  surveyForms:SurveyForm[] = []
  buildingBlocks:{ id:number, name:string }[] = []
  buildings:{ id:number, number:string }[] = []
  employees:{ id:number, full_name:string }[] = []

  @Watch('currentItem.project_id')
  onProjectChange () : void {
    if (this.currentItem.project_id) {
      this.currentItem.scheduled_user_id = null
      this.getSurveyForms()
      this.getBuildingBlocks()
      this.getUsers()
    }
  }

  @Watch('currentItem.building_block_id')
  onBuildingBlockChange () : void {
    if (this.currentItem.building_block_id) {
      this.getBuildings()
    }
  }

  mounted () : void {
    this.getProjects()

    if (this.currentItem.project_id) {
      this.getSurveyForms()
      this.getBuildingBlocks()
      this.getUsers()

      if (this.currentItem.building_block_id) {
        this.getBuildings()
      }
    }
  }

  getProjects () : void {
    axios.get(process.env.VUE_APP_API_URL + '/projects?limit=2500').then((res) => {
      this.projects = res.data.data
    })
  }

  getSurveyForms () : void {
    axios.get(process.env.VUE_APP_API_URL + '/surveys/forms?filter[active]=1&projectId=' + this.currentItem.project_id + '&limit=5000').then((res) => {
      this.surveyForms = res.data.data
    })
  }

  getBuildingBlocks () : void {
    axios.get(process.env.VUE_APP_API_URL + '/buildingblocks?filter[project_id]=' + this.currentItem.project_id + '&limit=5000').then((res) => {
      this.buildingBlocks = res.data.data
    })
  }

  getBuildings () : void {
    axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[buildingblock_id]=' + this.currentItem.building_block_id + '&limit=5000').then((res) => {
      this.buildings = res.data.data
    })
  }

  getUsers () : void {
    axios.get(process.env.VUE_APP_API_URL + '/members/' + this.currentItem.project_id + '/surveyInspectors').then((res) => {
      this.employees = res.data
    })
  }

  contentChanged (key:string, value:string) : void {
    this.currentItem[key] = value
  }

  filterDropdown (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  moment (dateTime:string, format:string) : moment.Moment {
    return moment(dateTime, format)
  }

  get currentItem () : Survey {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get selectedSurveyForm () : SurveyForm|null {
    let selectedSurveyForm = null

    if (!this.currentItem || !this.currentItem.survey_form_id) return selectedSurveyForm

    this.surveyForms.forEach((surveyForm:SurveyForm) => {
      if (surveyForm.id === this.currentItem.survey_form_id) {
        selectedSurveyForm = surveyForm
      }
    })

    return selectedSurveyForm
  }

  get surveyCompleted () : boolean {
    return this.currentItem.status === 4
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
