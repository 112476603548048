





































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Issue from '@/types/issue'
import TextEditor from '@/components/base/TextEditor.vue'
import storeSettings from '@/types/storeSettings'
import Company from '@/types/company'
import User from '@/types/user'

@Component({
  components: { TextEditor }
})

export default class SolverSelector extends Vue {
  @Prop({ required: true }) settings!: storeSettings
  showInactive = true
  search = {
    company: ''
  }

  @Watch('currentItem.solver_company_id')
  onSolverCompanyChanged (): void {
    this.resetSelectedSolver()
    this.$store.dispatch('issueStore/getSolvers', this.currentItem.solver_company_id)
  }

  mounted (): void {
    this.$store.dispatch('issueStore/getCompanies')
    this.TryGetSolvers()
  }

  TryGetSolvers (): void {
    this.$store.dispatch('issueStore/clearSolvers')
    if (this.currentItem.solver_company_id > 0) {
      this.$store.dispatch('issueStore/getSolvers', this.currentItem.solver_company_id)
    } else {
      this.resetSelectedSolver()
    }
  }

  get currentItem (): Issue {
    return this.$store.getters['issueStore/current']
  }

  get companies (): Company[] {
    return this.$store.getters['issueStore/companies']
      .filter((company: Company) => company.name.toLowerCase().includes(this.search.company.toLowerCase()))
      .sort((a: Company, b: Company) => a.name.localeCompare(b.name))
  }

  get solvers (): User[] {
    const sortedSolvers = this.$store.getters['issueStore/solvers'].sort((a: User, b: User) => {
      if (a.primary && !b.primary) return -1
      if (!a.primary && b.primary) return 1
      return a.full_name.localeCompare(b.full_name)
    })

    if (sortedSolvers.length > 0 && this.currentItem.solver_id === 0) {
      this.currentItem.solver_id = sortedSolvers[0].id
      this.$store.dispatch('issueStore/setSolverId', sortedSolvers[0].id)
    }

    return this.showInactive ? sortedSolvers : sortedSolvers.filter((solver: User) => solver.active)
  }

  FilterInactive (open: boolean): void {
    this.showInactive = !open
  }

  resetSelectedSolver (): void {
    this.$store.dispatch('issueStore/setSolverId', 0)
    this.currentItem.solver_id = 0
  }

  filterOptions (input: string, option: { componentOptions: { children: { text: string }[] } }): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
}
