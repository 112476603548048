




































import { Vue, Component, Prop } from 'vue-property-decorator'
import User from '@/types/user'
import SecureLS from 'secure-ls'
import TableSettings from '@/types/tableSettings'
import ServiceItem from '@/types/serviceItem'
import axios from 'axios'

function capitalize (str:string) : string {
  return str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase()
  })
}

@Component({
  components: {}
})

export default class RelatedServiceItems extends Vue {
  @Prop({ required: true }) currentItem!:ServiceItem
  @Prop({ required: true }) buildingId!:number
  @Prop({ required: true }) entity!:string
  @Prop({ required: true }) entities!:string
  @Prop({ required: true, default: false }) rental!:boolean

  settings = {
    routeNames: { list: this.entities, show: this.entity + 'Show', edit: this.entity + 'Edit' },
    routeParamKey: this.entity + 'Id',
    permissionNames: { show: this.entities + '.view', edit: this.entities + '.edit' }
  }

  relatedItems:ServiceItem[] = []

  conditions = {
    status: []
  }

  tableSettings:TableSettings = new TableSettings(this.conditions)

  columns = [
    {
      title: 'Referentie',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' },
      width: 120
    },
    {
      title: 'Onderwerp',
      key: 'title',
      dataIndex: 'title'
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      scopedSlots: { customRender: 'status' }
    },
    {
      title: 'Actie',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  searchTerm = ''

  mounted (): void {
    this.fetchRelatedItems()
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes(this.settings.permissionNames.edit)) {
            const route = this.$router.resolve({ name: this.settings.routeNames.edit, params: { [this.settings.routeParamKey]: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          } else if (this.authUser.permissions.includes(this.settings.permissionNames.show)) {
            const route = this.$router.resolve({ name: this.settings.routeNames.show, params: { [this.settings.routeParamKey]: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  fetchRelatedItems (): void {
    axios.get(process.env.VUE_APP_API_URL + '/' + this.entities + '?building.id=' + this.buildingId)
      .then((response) => {
        this.relatedItems = response.data.data
      })
      .catch((error) => {
        console.log(error)
      })
  }

  get items () : ServiceItem[] {
    let results:ServiceItem[] = this.relatedItems
    if (this.searchTerm !== '') {
      results = this.filterByValue(results, this.searchTerm)
    }
    results = results.filter(s => s.id !== this.currentItem.id)
    return results
  }

  search (value:string): void {
    this.searchTerm = value
  }

  filterByValue (array: ServiceItem[], string: string): any[] {
    string = string.toLowerCase()
    return array.filter((o:any) => {
      const result = Object.keys(o).filter(() => {
        return o.title.toLowerCase().includes(string) || o.id.toString().includes(string)
      })

      if (result.length > 0) {
        return o
      }
    })
  }
}
