































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Project, { Developer } from '@/types/project'
import moment from 'moment'
import Entity from '@/types/entity'
import axios from 'axios'
import ConstructionType from '@/types/constructionType'
import Uploader from '@/components/media/Uploader.vue'
import Filelist from '@/components/media/Filelist.vue'
import ProjectGoodNewsLetter from '@/components/project/ProjectGoodNewsLetter.vue'
import AfasField from '@/components/afas/AfasField.vue'

@Component({
  components: { AfasField, ProjectGoodNewsLetter, Filelist, Uploader }
})

export default class ProjectEditDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject', errors: 'projectErrors' }
  }

  buildings:Building[] = []
  entities:Entity[] = []
  companies:Developer[] = []
  constructionTypes:ConstructionType[] = []
  timer = 0

  mounted () : void {
    if (this.currentItem.id) {
      this.getBuildings()
    }

    if (this.currentItem.developer) {
      this.companies.push(this.currentItem.developer)
    }

    this.getEntities()
    this.getConstructionTypes()
  }

  getBuildings () : void {
    axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[project_id]=' + this.currentItem.id).then((res) => {
      this.buildings = res.data.data
    })
  }

  getEntities () : void {
    axios.get(process.env.VUE_APP_API_URL + '/entities').then((res) => {
      this.entities = res.data.data
    })
  }

  getConstructionTypes () : void {
    axios.get(process.env.VUE_APP_API_URL + '/constructionTypes').then((res) => {
      this.constructionTypes = res.data.data
    })
  }

  newFileUploaded (file:File, key:string) : void {
    const formData = new FormData()
    formData.append('position', key)
    formData.append('file', file)

    axios.post(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.id + '/media', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    })
  }

  reloadProject () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  searchCompanies (val:string) : void {
    if (val.length >= 3) {
      if (val.length >= 3) {
        clearTimeout(this.timer)

        this.timer = setTimeout(() => {
          axios.get(process.env.VUE_APP_API_URL + '/companies?filter[search]=' + val).then((res) => {
            this.companies = res.data.data
          })
        }, 500)
      }
    }
  }

  moment () : moment.Moment {
    return moment()
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get isAfasProject () : boolean {
    return this.currentItem.in_afas
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
