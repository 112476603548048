













































import { Vue, Component, Prop } from 'vue-property-decorator'
import { capitalize } from 'lodash'

@Component({
  components: {}
})
export default class PropertyBlock extends Vue {
  @Prop({ required: true, default: () => [] }) properties!: { id: number, name: string, value: string }[]
  @Prop({ required: false, default: false }) busy!: boolean

  addingProperty = false
  newPropertyName = ''
  newPropertyValue = ''
  editPropertyName = ''
  editPropertyValue = ''
  editingPropertyId = -1

  columns = [
    { title: 'Naam', dataIndex: 'name', key: 'name', scopedSlots: { customRender: 'name' } },
    { title: 'Waarde', dataIndex: 'value', key: 'value', scopedSlots: { customRender: 'value' } },
    { title: 'Actie', key: 'action', scopedSlots: { customRender: 'action' }, width: '120px', align: 'center' }
  ]

  addProperty () : void {
    this.addingProperty = true
    this.$nextTick(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$refs.newPropertyName.focus()
    })
  }

  get canAddProperty () : boolean {
    return this.newPropertyName !== '' && this.newPropertyValue !== '' && !this.busy
  }

  get canUpdateProperty () : boolean {
    return this.editPropertyName !== '' && this.editPropertyValue !== '' && !this.busy
  }

  cancelProperty () : void {
    this.addingProperty = false
  }

  saveProperty () : void {
    if (!this.canAddProperty) return

    this.$emit('created', { name: capitalize(this.newPropertyName), value: capitalize(this.newPropertyValue) })
    this.addingProperty = false
    this.newPropertyName = ''
    this.newPropertyValue = ''
  }

  editProperty (property: { id: number, name: string, value: string }) : void {
    this.editPropertyName = property.name
    this.editPropertyValue = property.value
    this.editingPropertyId = property.id

    this.$nextTick(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$refs.editPropertyName.select()
    })
  }

  updateProperty (property: { id: number, name: string, value: string }) : void {
    const updatedProperties = this.properties.map(p => {
      if (p.id === property.id) {
        return { id: p.id, name: capitalize(this.editPropertyName), value: capitalize(this.editPropertyValue) }
      }
      return p
    })
    this.editingPropertyId = -1
    if (JSON.stringify(updatedProperties) === JSON.stringify(this.properties)) return
    this.$emit('updated', updatedProperties)
  }

  cancelEditProperty () : void {
    this.editingPropertyId = -1
  }

  deleteProperty (property: { id: number, name: string, value: string }) : void {
    this.$emit('deleted', property.id)
  }
}
