import { Base64MediaFile } from '@/types/media'
import User from '@/types/user'

export default class ServiceComment {
  public id?:number
  public parent_id?:number|null = null
  public user_id!:number
  public user?: User
  public body = ''
  public status!: number
  public shared: {
    client: boolean
    solver: boolean
  } = {
    client: false,
    solver: false
  }

  public waitingForResponse = false
  public mentions:number[] = []
  public mentioned: { id: number, full_name:string, active:boolean }[] = []
  public source = ''
  public read_by_client = false
  public read_by_solver = false
  public created_at?:string
  public updated_at?:string
  public files:File[] = []
  public media: Base64MediaFile[] = []
  public is_pinned = false
}
