






































import { Vue, Component, Watch } from 'vue-property-decorator'
import RevitElementDetailsTab from '@/views/element/tabs/RevitElementDetailsTab.vue'
import RevitElementSubElementsTab from '@/views/element/tabs/RevitElementSubelementsTab.vue'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import RevitElement from '@/types/revitElement'
import PropertyBlock from '@/views/element/PropertyBlock.vue'
import axios from 'axios'

@Component({
  components: {
    PropertyBlock,
    RevitElementDetailsTab,
    RevitElementSubElementsTab
  }
})
export default class RevitElementView extends Vue {
  @Watch('$route', { immediate: true, deep: true })
  onUrlChange () : void {
    this.$store.dispatch('revitElementStore/setCurrentItem', this.routeId)
  }

  ls = new SecureLS({ isCompression: false })
  listRoute = 'revitElements'
  activeTabKey = 'revitElementShowActiveTab'
  editModeActive = false

  settings = {
    functionNames: { setCurrent: 'setCurrentElement', clearCurrent: 'clearCurrentElement', save: 'saveElement' },
    getterNames: { current: 'currentElement', loading: 'loadingElement' }
  }

  currentTab:string = this.ls.get(this.activeTabKey) || 'details'

  mounted () : void {
    this.$store.dispatch('revitElementStore/setCurrentItem', this.routeId)
  }

  createdProperty (property: { name: string, value: string }) : void {
    axios.post(process.env.VUE_APP_API_URL + '/revit-elements/' + this.routeId + '/property', property)
      .then(() => {
        this.$store.dispatch('revitElementStore/setCurrentItem', this.routeId)
      })
  }

  updateProperties (properties: { id: number, name: string, value: string }[]) : void {
    axios.post(process.env.VUE_APP_API_URL + '/revit-elements/' + this.routeId + '/properties', { properties: properties })
      .then(() => {
        this.$store.dispatch('revitElementStore/setCurrentItem', this.routeId)
      })
  }

  deleteProperty (propertyId: number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/revit-elements/' + this.routeId + '/property/' + propertyId)
      .then(() => {
        this.$store.dispatch('revitElementStore/setCurrentItem', this.routeId)
      })
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : RevitElement {
    return this.$store.getters['revitElementStore/currentItem']
  }

  get routeId () : number {
    return parseInt(this.$route.params.elementId)
  }

  get editable () : boolean {
    return this.editModeActive && this.authUser.permissions.includes('elements.edit')
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
